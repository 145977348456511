// fetch polyfill
// require('es6-promise').polyfill();
require('whatwg-fetch');

// import css before other
// files to preserve ordering
import './bundle.wellness.css';

// import FC deps
import '@wellness-tags/Icon/IconSet';
import '@lib/theme-handler';
// import '@lib/offline-runtime';

// import npm modules
//import select from 'dom-select';

// import elements js
import GlobalHeader from '@wellness-components/GlobalHeader/_GlobalHeader';
import Registration from '@wellness-components/Registration/_Registration';
import GoalCard from '@wellness-tags/GoalCard/_GoalCard';
import Modal from '@wellness-tags/Modal/_Modal';
import RequiredCheckbox from '@wellness-tags/RequiredCheckbox/_RequiredCheckbox';
import ConditionalSubmit from '@wellness-modifiers/ConditionalSubmit/ConditionalSubmit';
import ConfirmInput from '@wellness-modifiers/ConfirmInputs/ConfirmInput';
import FieldMask from '@wellness-modifiers/FieldMask/FieldMask';
import PrintLink from '@wellness-modifiers/PrintLink/PrintLink';
import RadioToggle from '@wellness-modifiers/RadioToggle/RadioToggle';
import SelectNav from '@wellness-modifiers/SelectNav/SelectNav';

// program
const html = document.querySelector('html');

// remove no-js class
html.classList.remove('no-js');

// initialize components js
function componentSetup() {
  const registration = document.getElementById('Registration');
  if (registration) {
    new Registration({ el: registration });
  }

  const globalHeaders = document.getElementsByClassName('GlobalHeader');
  for (let i = 0; i < globalHeaders.length; i++) {
    new GlobalHeader({ el: globalHeaders[i] });
  }

  const goalCards = document.getElementsByClassName('GoalCard');
  for (let i = 0; i < goalCards.length; i++) {
    new GoalCard({ el: goalCards[i] });
  }

  const modals = document.getElementsByClassName('Modal');
  for (let i = 0; i < modals.length; i++) {
    new Modal({ el: modals[i] });
  }

  const requiredCheckboxes = document.getElementsByClassName('RequiredCheckbox');
  for (let i = 0; i < requiredCheckboxes.length; i++) {
    new RequiredCheckbox({ el: requiredCheckboxes[i] });
  }

  const conditionalSubmits = document.getElementsByClassName('ConditionalSubmit');
  for (let i = 0; i < conditionalSubmits.length; i++) {
    new ConditionalSubmit({ el: conditionalSubmits[i] });
  }

  const confirmInputs = document.getElementsByClassName('ConfirmInput');
  for (let i = 0; i < confirmInputs.length; i++) {
    new ConfirmInput({ el: confirmInputs[i] });
  }

  const fieldMasks = document.getElementsByClassName('FieldMask');
  for (let i = 0; i < fieldMasks.length; i++) {
    new FieldMask({ el: fieldMasks[i] });
  }

  const printLinks = document.getElementsByClassName('PrintLink');
  for (let i = 0; i < printLinks.length; i++) {
    new PrintLink({ el: printLinks[i] });
  }

  const radioToggles = document.querySelectorAll('input[type="radio"].RadioToggle');
  for (let i = 0; i < radioToggles.length; i++) {
    new RadioToggle({ el: radioToggles[i] });
  }

  const selectNavs = document.querySelectorAll('select.SelectNav');
  for (let i = 0; i < selectNavs.length; i++) {
    new SelectNav({ el: selectNavs[i] });
  }
}

document.addEventListener('DOMContentLoaded', componentSetup);
