class ModalToggle {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.modal = props.modal;
      this.closeAllModals = props.closeAllModals;
      this.openModal = props.openModal;
      this.init();
    }
  }

  init() {
    this.el.addEventListener('click', (e) => {
      e.preventDefault();

      // check to see if the target modal is already open
      const modalIsOpen = this.modal.classList.contains('Modal--active');

      // close all open modals
      this.closeAllModals();

      // open target modal if it was closed before
      !modalIsOpen && this.openModal(this.modal);

      // blur toggle
      this.el.blur();
    });
  }
}

export default ModalToggle;
