const Cleave = require('cleave.js').default;

class FieldMask {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    const self = this.el;
    const maskType = self.getAttribute('data-mask') || false;
    const maxValues = { ssn: 4, ghpID: 11 };

    // only proceed if a mask type is provided
    if (maskType) {
      switch (maskType) {
        case 'phone':
          new Cleave(self, {
            numericOnly: true,
            blocks: [3, 3, 4],
            delimiters: ['-', '-', '-']
          });
          break;

        case 'date':
          new Cleave(self, {
            date: true,
            datePattern: ['m', 'd', 'Y']
          });
          break;

        case 'ssn':
        case 'ghpID':
          new Cleave(self, {
            numericOnly: true,
            blocks: [maxValues[maskType]]
          });
          break;

        default:
          // do nothing if unsupported mask type provided
          break;
      }
    }
  }
}

module.exports = FieldMask;
