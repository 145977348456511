class ConfirmInput {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    this.el.addEventListener('click', (e) => {
      const requiredInputsEl = checkRequiredInputs(e.target);
      if (!requiredInputsEl) {
        e.preventDefault();
      }
    });
  }
}

const checkRequiredInputs = (button) => {
  const requiredInputs = button.getAttribute('data-confirm-inputs');
  const requiredInputsEl = [];
  let passthrough = true;

  if (!requiredInputs) {
    return passthrough;
  }

  const buttonDependencies = requiredInputs.split(',');

  for (let i = 0; i < buttonDependencies.length; i++) {
    const dependencyEl = document.getElementById(buttonDependencies[i]);
    if (dependencyEl) {
      requiredInputsEl.push(dependencyEl);
    }
  }

  const firstParent = requiredInputsEl[0].parentNode;
  const matchValue = requiredInputsEl[0].value;

  for (let i = 1; i < requiredInputsEl.length; i++) {
    if (requiredInputsEl[i].value !== matchValue || requiredInputsEl[i].value.length < 1) {
      setParentNodeInvalid(firstParent, false);
      passthrough = false;
    } else {
      setParentNodeInvalid(firstParent, true);
    }
  }
  return passthrough;
};

const setParentNodeInvalid = (parent, value) => {
  console.log(parent);
  if (parent) {
    if (parent.classList.contains('Fieldset') && parent.classList.contains('InputText')) {
      if (value === false) {
        parent.classList.add('is-invalid');
      } else {
        parent.classList.remove('is-invalid');
      }
    }
  }
};

module.exports = ConfirmInput;

