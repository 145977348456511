class PrintLink {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    const self = this.el;
    self.addEventListener('click', (e) => {
      if (e.target.type !== 'radio') {
        e.preventDefault();
      }
      window.print();
    });
  }
}

module.exports = PrintLink;
