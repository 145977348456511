import debugJson from './data/success.json';

class ModalForm {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.closeAllModals = props.closeAllModals;
      this.clearErrors = props.clearErrors;
      this.openModal = props.openModal;
      this.loading = false;
      this.init();
    }
  }

  init() {
    const self = this.el;
    const debug = self.getAttribute('data-debug');
    const formAction = self.getAttribute('data-action') || '/path/to/api';
    const formErrorText = self.getAttribute('data-error-text') || 'Error!';
    const formRefresh = self.getAttribute('data-refresh') || null;
    const loadingOverlay = self.querySelector('.LoadingOverlay');
    const errorAlert = self.querySelector('.Modal__error');
    const stepFormFields = self.querySelectorAll('input, select, textarea');
    const nextModalId = self.getAttribute('data-next-modal');
    const nextModal = nextModalId && document.querySelector(`#${nextModalId}`);
    let nextModalMessageContainer = false;
    if (nextModal) {
      const nextModalMessageParent = nextModal.querySelector('.Rhythm--default');
      const pTag = document.createElement('p');
      pTag.classList.add('has-message');
      pTag.appendChild(document.createTextNode(''));
      nextModalMessageParent.appendChild(pTag);
      nextModalMessageContainer = nextModal.querySelector('.has-message');
    }

    self.addEventListener('submit', (e) => {
      e.preventDefault();

      // prevent submission if already loading
      if (this.loading) {
        return false;
      }

      // enable loading overlay
      this.handleLoading(true, loadingOverlay);

      // clear old errors
      this.clearErrors(this.el);

      // collect & store form data
      const formData = formToJson(stepFormFields);

      // post formData to api & handle response
      (debug ? postDataLocal : postData)(formData, formAction).then(
        (data) => {
          // disable loading overlay
          this.handleLoading(false, loadingOverlay);

          if (data.error) {
            // handle form-level error
            data.errorMessage && handleFormError(errorAlert, data.errorMessage);

            // handle field-level errors
            data.fieldErrors && handleFieldErrors(data.fieldErrors);
          } else if (formRefresh) {
            // no errors were returned
            // modal form is in refresh mode
            location.reload(true);
          } else {
            // no errors were returned
            // modal form not in refresh mode
            this.closeAllModals();
            if (nextModal && nextModalMessageContainer) {
              nextModalMessageContainer.innerHTML = data.message || '';
              this.openModal(nextModal);
            }
          }
        }
      ).catch(
        () => {
          // api error encountered, display html-derived error messaged
          handleFormError(errorAlert, formErrorText);

          // disable loading overlay
          this.handleLoading(false, loadingOverlay);
        }
      );

      return true;
    });
  }

  handleLoading(loading, loadingOverlay) {
    this.loading = loading;

    // toggle loading overlays
    if (loading && loadingOverlay) {
      loadingOverlay.classList.add('LoadingOverlay--active');
    } else if (loadingOverlay) {
      loadingOverlay.classList.remove('LoadingOverlay--active');
    }
  }
}

const postDataLocal = (payload, formAction) => {
  'console' in window && console.log(`postDataLocal: ${formAction}`, payload);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(debugJson);
    }, 1500);
  });
};

const postData = (payload, formAction) => (
  fetch(formAction, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((r) => r.json())
    .then((data) => data)
);

const formToJson = (fields) => {
  const obj = {};
  for (let i = 0; i < fields.length; i++) {
    const e = fields[i];
    const name = e.name;
    if (name) {
      switch (e.type) {
        case 'radio':
          if (e.checked) {
            obj[name] = e.value;
          }
          break;
        case 'checkbox':
          obj[name] = e.checked;
          break;
        default:
          obj[name] = e.value;
          break;
      }
    }
  }
  return obj;
};

const handleFormError = (el, errorMessage) => {
  el.textContent = errorMessage;
  el.classList.remove('Alert--hidden');
};

const handleFieldErrors = (fieldErrors) => {
  for (let i = 0; i < fieldErrors.length; i++) {
    const fieldError = fieldErrors[i];
    const el = fieldError.id ? document.getElementById(fieldError.id) : null;
    if (el) {
      el.classList.remove('Alert--hidden');
      el.textContent = !fieldError.message ? 'Error!' : fieldError.message;
    }
  }
};

export default ModalForm;
