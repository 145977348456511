class GoalCard {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    // handle GoalCard toggles
    const toggles = this.el.getElementsByClassName('GoalCard__toggle');
    for (let i = 0; i < toggles.length; i++) {
      const toggle = toggles[i];
      const titleActive = toggle.getAttribute('data-title-active') || 'Close';
      const titleInactive = toggle.getAttribute('data-title-inactive') || 'Open';

      // click event listener for toggle
      toggle.addEventListener('click', (e) => {
        e.preventDefault();

        // toggle active class for GoalCard tag
        this.el.classList.toggle('GoalCard--active');

        // toggle active/inactive title for toggle element
        toggle.title = this.el.classList.contains('GoalCard--active') ? titleActive : titleInactive;
      });
    }
  }
}

module.exports = GoalCard;
