class RadioToggle {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    const radio = this.el;
    const radioGroup = document.querySelectorAll(`input[type="radio"][name="${radio.name}"]`);
    const targetId = radio.getAttribute('data-radio-toggle-id');
    const targetEl = targetId ? document.getElementById(targetId) : null;
    const targetElInput = targetEl ? targetEl.querySelector('input') : null;
    // add active class to target if valid & radio is checked by default
    (radio.checked && targetEl) && targetEl.classList.add('RadioToggle__active');

    if (!radio.checked && targetElInput) {
      targetElInput.required = false;
    }

    // add listener if radio has a valid target
    radio.addEventListener('change', (e) => {
      e.preventDefault();

      // first we'll loop through the options in radioGroup
      for (let i = 0; i < radioGroup.length; i++) {
        // not every radio will have a target so we need to check for one
        const thisTargetId = radioGroup[i].getAttribute('data-radio-toggle-id');
        const thisTargetEl = thisTargetId ? document.getElementById(thisTargetId) : null;
        const thisTargetElInput = thisTargetEl ? thisTargetEl.querySelector('input') : null;
        const thisTargetLabelRequired = thisTargetEl ? thisTargetEl.querySelector('.Fieldset__label--required') : null;
        // only take action if a target is found
        thisTargetEl && thisTargetEl.classList.remove('RadioToggle__active');

        if (thisTargetLabelRequired && thisTargetElInput.required) {
          thisTargetElInput.required = false;
        }
      }

      // add the active class to targetEl
      if (targetEl && targetEl.querySelector('.Fieldset__label--required')) {
        targetEl.querySelector('input').required = true;
      }
      targetEl && targetEl.classList.add('RadioToggle__active');
    });
  }
}

module.exports = RadioToggle;
