class RequiredCheckbox {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    const checkboxEl = this.el.querySelector('input[type="checkbox"]');
    checkboxEl && checkboxEl.addEventListener('change', (e) => {
      e.preventDefault();
      this.el.classList.toggle('RequiredCheckbox--checked');
    });
  }
}

module.exports = RequiredCheckbox;
