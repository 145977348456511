import jump from 'jump.js';
import RegistrationStep from './_RegistrationStep';

class Registration {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.debug = this.el.getAttribute('data-debug');
      this.stepWrappers = {};
      this.stepClasses = {};
      this.init();
    }
  }

  init() {
    // loop through step wrappers to define this.stepWrappers
    const stepWrappers = document.getElementsByClassName('Registration--step');
    for (let i = 0; i < stepWrappers.length; i++) {
      const stepWrapper = stepWrappers[i];
      if (stepWrapper.id) {
        this.stepWrappers[stepWrapper.id] = stepWrapper;
      }
    }

    // initialize first step
    stepWrappers[0].id && this.setActiveStep(stepWrappers[0].id);
  }

  setActiveStep(activeStep) {
    // show the new active step wrapper, hide the rest
    handleStepWrappers(this.stepWrappers, activeStep);

    // check that step is indexed before handling it
    if (this.stepWrappers[activeStep]) {
      if (this.stepClasses[activeStep]) {
        // reinitialize step if already active
        this.stepClasses[activeStep].reInit();

        // jump to top of step wrapper
        jumpTo(this.stepWrappers[activeStep]);
      } else {
        // initialize step if not active yet
        this.stepClasses[activeStep] = new RegistrationStep({
          el: this.stepWrappers[activeStep],
          debug: this.debug,
          setActiveStep: this.setActiveStep.bind(this)
        });

        // jump to top of step wrapper if not first step
        if (Object.keys(this.stepWrappers)[0] !== activeStep) {
          jumpTo(this.stepWrappers[activeStep]);
        }
      }
    }
  }
}

const handleStepWrappers = (stepWrappers, activeStep) => {
  Object.keys(stepWrappers).forEach((stepWrapperId) => {
    if (stepWrapperId === activeStep) {
      stepWrappers[stepWrapperId].classList.add('Registration--step__active');
    } else {
      stepWrappers[stepWrapperId].classList.remove('Registration--step__active');
    }
  });
};

const jumpTo = (el) => {
  jump(el, {
    duration: 500
  });
};

export default Registration;
