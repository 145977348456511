class SelectNav {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    const self = this.el;

    // redirect visitor to url in value of selected option
    self.addEventListener('change', () => {
      window.location = self.value;
    });
  }
}

module.exports = SelectNav;
