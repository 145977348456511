const requireAll = (context) => context.keys().map(context);

// import variables before components
require('./elements/variables/index.css');

// require any components that
// need to preserve ordering
require('@lib/sharedRootCss/sharedRoot.css');
require('./elements/tags/Root/Root.css');
require('./elements/tags/RichText/RichText.css');

// require the new identifier
require('./elements/modifiers/ExperienceEditor/ExperienceEditor.css');

// require the rest of the components
requireAll(require.context('./elements/tags/', true, /\.css$/));
requireAll(require.context('./elements/components/', true, /\.css$/));
requireAll(require.context('./elements/compositions/', true, /\.css$/));
requireAll(require.context('./elements/modifiers/', true, /\.css$/));
