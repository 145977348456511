import jump from 'jump.js';
import debugJson from './data/success.json';

class RegistrationStep {
  constructor(props) {
    this.el = props.el;
    this.debug = props.debug;
    this.setActiveStep = props.setActiveStep;
    this.loading = false;
    this.stepId = this.el.id;
    this.stepForm = this.el.querySelector('form');
    this.stepError = this.el.querySelector('.Registration--step-error');
    this.stepForm && this.init();
  }

  init() {
    // handle form submission
    const stepFormFields = this.stepForm.querySelectorAll('input, select, textarea') || null;
    const stepFormAction = this.stepForm.getAttribute('data-action') || '/path/to/api';
    const nextStep = this.stepForm.getAttribute('data-next-step') || null;
    const stepFormError = this.stepForm.getAttribute('data-error-message') || null;
    const loadingOverlay = this.el.querySelector('.LoadingOverlay');
    nextStep && this.stepForm.addEventListener('submit', (e) => {
      e.preventDefault();

      // prevent submission if already loading
      if (this.loading) {
        return false;
      }

      // enable loading overlay
      this.handleLoading(true, loadingOverlay);

      // clear old errors
      clearErrors(this.el);

      // collect & store form data
      const formData = formToJson(stepFormFields);
      const stepData = {
        stepId: this.stepId,
        stepData: formData
      };

      // post formData to api & handle response
      (this.debug ? postDataLocal : postData)(stepData, stepFormAction).then(
        (data) => {
          // disable loading overlay
          this.handleLoading(false, loadingOverlay);

          if (!data.error) {
            // proceed to next step
            nextStep && this.setActiveStep(nextStep);
          } else if (this.stepError) {
            // errors found, handle step-level errors
            data.errorMessage && handleStepErrors(this.stepError, data.errorMessage);

            // handle field-level errors
            data.fieldErrors && handleFieldErrors(data.fieldErrors);

            // jump to top of step wrapper
            jumpTo(this.el);
          }
        }
      ).catch(
        () => {
          // there was an issue commincating with the api or parsing its response
          if (this.stepError) {
            this.stepError.textContent = !stepFormError ? 'Error!' : stepFormError;
            this.stepError.classList.remove('Alert--hidden');
          }

          // disable loading overlay
          this.handleLoading(false, loadingOverlay);
        }
      );

      return true;
    });

    // handle back buttons
    const prevButtons = this.el.getElementsByClassName('Registration--button-prev');
    for (let i = 0; i < prevButtons.length; i++) {
      const prevStep = prevButtons[i].getAttribute('data-prev-step');
      prevStep && prevButtons[i].addEventListener('click', (e) => {
        e.preventDefault();
        this.setActiveStep(prevStep);
      });
    }
  }

  reInit() {
    // clear old errors
    clearErrors(this.el);
  }

  handleLoading(loading, loadingOverlay) {
    this.loading = loading;

    // toggle loading overlays
    if (loading && loadingOverlay) {
      loadingOverlay.classList.add('LoadingOverlay--active');
    } else if (loadingOverlay) {
      loadingOverlay.classList.remove('LoadingOverlay--active');
    }
  }
}

const postDataLocal = (payload, action) => {
  'console' in window && console.log(`postDataLocal: ${action}`, payload);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(debugJson);
    }, 1500);
  });
};

const postData = (payload, action) => (
  fetch(action, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((r) => r.json())
    .then((data) => data)
);

const formToJson = (fields) => {
  const obj = {};
  for (let i = 0; i < fields.length; i++) {
    const e = fields[i];
    const name = e.name;
    if (name) {
      switch (e.type) {
        case 'radio':
          if (e.checked) {
            obj[name] = e.value;
          }
          break;
        case 'checkbox':
          obj[name] = e.checked;
          break;
        default:
          obj[name] = e.value;
          break;
      }
    }
  }
  return obj;
};

const clearErrors = (el) => {
  const activeErrors = el.querySelectorAll('.Alert:not(.Alert--hidden)');
  for (let i = 0; i < activeErrors.length; i++) {
    const activeError = activeErrors[i];
    activeError.classList.add('Alert--hidden');
    activeError.textContent = '';
  }
};

const handleStepErrors = (el, errorMessage) => {
  el.textContent = errorMessage;
  el.classList.remove('Alert--hidden');
};

const handleFieldErrors = (fieldErrors) => {
  for (let i = 0; i < fieldErrors.length; i++) {
    const fieldError = fieldErrors[i];
    const el = fieldError.id ? document.getElementById(fieldError.id) : null;
    if (el) {
      el.classList.remove('Alert--hidden');
      el.textContent = !fieldError.message ? 'Error!' : fieldError.message;
    }
  }
};

const jumpTo = (el) => {
  jump(el, {
    duration: 500
  });
};

export default RegistrationStep;
