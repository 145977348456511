import debounce from 'lodash.debounce';

class GlobalHeader {
  constructor(props) {
    if (props.el) {
      // elements
      this.el = props.el;
      this.headerOverlay = this.el.querySelector('.GlobalHeader__overlay');

      // data
      this.windowWidth = window.innerWidth;

      // events
      this.windowResizeEvent = debounce(this.handleWindowResize.bind(this), 100);
      this.overlayClickEvent = this.handleOverlayClick.bind(this);

      // off we go!
      this.init();
    }
  }

  init() {
    // index navigation toggle elements
    const navToggles = this.el.getElementsByClassName('Navigation__toggle');

    // loop through navToggles
    for (let i = 0; i < navToggles.length; i++) {
      const navToggle = navToggles[i];

      // check for toggle targets
      const navToggleTargets = navToggle.getAttribute('data-toggle-id') ? navToggle.getAttribute('data-toggle-id').split(',') : '';
      const navToggleTargetsEl = [];

      // loop through toggle targets and build array of elements founds
      for (let ii = 0; ii < navToggleTargets.length; ii++) {
        const targetEl = document.getElementById(navToggleTargets[ii]);
        targetEl && navToggleTargetsEl.push(targetEl);
      }

      // attach click listener to navToggle (opens target nav)
      navToggle.addEventListener('click', (e) => {
        e.preventDefault();

        // check if toggled nav is already active
        const isActive = navToggle.classList.contains('Navigation__icon--active');

        // close open navs before opening new one
        resetHeader(this.el);

        // open new nav if it wasn't already active
        if (!isActive) {
          // window resize listener is needed when header is open
          window.addEventListener('resize', this.windowResizeEvent);

          // add click listener for headerOverlay (closes everything)
          this.headerOverlay && this.headerOverlay.addEventListener('click', this.overlayClickEvent);

          // add active state to header overlay
          this.headerOverlay && this.headerOverlay.classList.add('GlobalHeader__overlay--active');

          // update this.windowWidth
          this.windowWidth = window.innerWidth;

          // add active class to nav icon
          navToggle.classList.add('Navigation__icon--active');

          // loop through toggle target elements to activate them
          for (let ii = 0; ii < navToggleTargetsEl.length; ii++) {
            navToggleTargetsEl[ii].classList.add('Navigation--active');
          }
        } else {
          // window resize listener not needed when header is closed
          window.removeEventListener('resize', this.windowResizeEvent);

          // remove click listener for headerOverlay (closes everything)
          this.headerOverlay && this.headerOverlay.removeEventListener('click', this.overlayClickEvent);

          // remove active state from header overlay
          this.headerOverlay && this.headerOverlay.classList.remove('GlobalHeader__overlay--active');
        }
      });
    }
  }

  handleOverlayClick() {
    // reset header state
    resetHeader(this.el);

    // remove active state from header overlay
    this.headerOverlay.classList.remove('GlobalHeader__overlay--active');
  }

  handleWindowResize() {
    // check if resize is horizontal
    if (this.windowWidth !== window.innerWidth) {
      // remove resize listener until needed again
      window.removeEventListener('resize', this.windowResizeEvent);

      // update this.windowWidth
      this.windowWidth = window.innerWidth;

      // close overlay
      this.headerOverlay && this.headerOverlay.classList.remove('GlobalHeader__overlay--active');

      // reset header state
      resetHeader(this.el);
    }
  }
}

const resetHeader = (el) => {
  // remove active state from header icons
  const activeIcons = el.getElementsByClassName('Navigation__icon--active');
  while (activeIcons.length > 0) {
    activeIcons[0].classList.remove('Navigation__icon--active');
  }

  // remove active state from header nav menus
  const activeNavs = el.getElementsByClassName('Navigation--active');
  while (activeNavs.length > 0) {
    activeNavs[0].classList.remove('Navigation--active');
  }
};

export default GlobalHeader;
