import ModalToggle from './_ModalToggle';
import ModalForm from './_ModalForm';

class Modal {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    // handle elements that toggle this modal
    const modalToggles = document.querySelectorAll(`.Modal__toggle[href="#${this.el.id}"]`);
    for (let i = 0; i < modalToggles.length; i++) {
      new ModalToggle({
        el: modalToggles[i],
        modal: this.el,
        closeAllModals,
        openModal
      });
    }

    // handle modal form (one allowed per modal)
    const modalForm = this.el.querySelector('form.Modal__form');
    modalForm && new ModalForm({
      el: modalForm,
      closeAllModals,
      clearErrors,
      openModal
    });
  }
}

const openModal = (el) => {
  // clear any existing modal errors
  clearErrors(el);

  // add active class to target modal
  el.classList.add('Modal--active');

  // add listeners for closing modals
  window.addEventListener('mouseup', handleClickToClose);
  window.addEventListener('keyup', handleEscToClose);
};

const closeAllModals = () => {
  // remove listeners for closing modals
  window.removeEventListener('mouseup', handleClickToClose);
  window.removeEventListener('keyup', handleEscToClose);

  // loop through active modals and remove active class
  const activeModals = document.getElementsByClassName('Modal--active');
  while (activeModals.length > 0) {
    activeModals[0].classList.remove('Modal--active');
  }
};

const handleClickToClose = (e) => {
  // close modals if background is clicked (not content area)
  (e.target.classList && e.target.classList.contains('Modal--active')) && closeAllModals();
};

const handleEscToClose = (e) => {
  // close modals if escape key is hit
  e.keyCode === 27 && closeAllModals();
};

const clearErrors = (el) => {
  const activeErrors = el.querySelectorAll('.Alert:not(.Alert--hidden)');
  for (let i = 0; i < activeErrors.length; i++) {
    const activeError = activeErrors[i];
    activeError.classList.add('Alert--hidden');
    activeError.textContent = '';
  }
};

export default Modal;
