class ConditionalSubmit {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.init();
    }
  }

  init() {
    const button = this.el;
    const buttonDependencies = button.getAttribute('data-required-checkboxes').split(',');
    const buttonOptionDependenciesAttr = button.getAttribute('data-required-options');
    const buttonOptionDependencies = buttonOptionDependenciesAttr ? buttonOptionDependenciesAttr.split(',') : [];
    const buttonDependenciesEl = [];

    // loop through dependencies (string) and build array of corresponding elements
    if (buttonDependencies) {
      for (let i = 0; i < buttonDependencies.length; i++) {
        const dependencyEl = document.getElementById(buttonDependencies[i]);
        if (dependencyEl) {
          (dependencyEl.type && dependencyEl.type === 'checkbox') && buttonDependenciesEl.push(dependencyEl);
        }
      }
    }

    if (buttonOptionDependencies) {
      for (let i = 0; i < buttonOptionDependencies.length; i++) {
        const dependency2El = document.getElementById(buttonOptionDependencies[i]);
        if (dependency2El) {
          (dependency2El.type && dependency2El.type === 'radio') && buttonDependenciesEl.push(dependency2El);
        }
      }
    }

    // loop through dependencies (checkbox elements) and attach onChange listeners
    if (buttonDependenciesEl) {
      button.disabled = !areAllChecked(buttonDependenciesEl) || !areAllSelected(buttonDependenciesEl);

      for (let i = 0; i < buttonDependenciesEl.length; i++) {
        const dependencyEl = buttonDependenciesEl[i];
        dependencyEl.addEventListener('change', (e) => {
          e.preventDefault();
          const notRequiredChecked = !areAllChecked(buttonDependenciesEl);
          const notOptionsSelect = !areAllSelected(buttonDependenciesEl);
          button.disabled = notOptionsSelect || notRequiredChecked;
        });
      }
    }
  }
}

const areAllChecked = (dependencies) => {
  /*
  loop through dependencies, return false if one is not checked
  elements passed into this function should be verified as checkboxes
   */
  for (let i = 0; i < dependencies.length; i++) {
    if (dependencies[i].type === 'checkbox') {
      if (!dependencies[i].checked) {
        return false;
      }
    }
  }

  return true;
};

const areAllSelected = (dependencies) => {
  /*
  loop through dependencies, return false if one is not checked
  elements passed into this function should be verified as checkboxes
   */
  const obj = {};
  const names = [];
  for (let i = 0; i < dependencies.length; i++) {
    const e = dependencies[i];
    if (e.type === 'radio') {
      names.push(e.name);
      if (e.checked) {
        obj[e.name] = true;
      }
    }
  }

  let passthrough = true;

  for (let k = 0; k < names.length; k++) {
    const currentName = names[k];
    if (!obj[currentName]) {
      passthrough = false;
    }
  }

  return passthrough;
};

module.exports = ConditionalSubmit;
